body {
    font-family: Arial, sans-serif;
    background: linear-gradient(to right, #0a1057 0%, #963c94 100%);
    color: #fff;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    text-align: center;
    max-width: 600px;
    padding: 20px;
    border-radius: 8px;
}

img {
    max-width: 150px;
    margin-bottom: 20px;
}

.hidden-text {
    display: none;
}

.highlight {
    color: #ff4747;
    font-weight: bold;
}

footer {
    margin-top: 20px;
    font-size: 14px;
    color: #888;
}
